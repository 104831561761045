import Vue from 'vue'
import {
  Autocomplete,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  MenuItem,
  Form,
  Input,
  FormItem
} from 'element-ui'

import AppLayout from './layout/index.vue'
import router from './router'
import store from './store'

import './scss/style.scss'

Vue.config.productionTip = false

Vue.use(Autocomplete)
Vue.use(Button)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Form)
Vue.use(Input)
Vue.use(FormItem)

function onWindowResize() {
  store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth)
}

new Vue({
  name: 'Root',
  router,
  store,
  mounted() {
    store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth)
    window.addEventListener('resize', onWindowResize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', onWindowResize)
  },
  render: h => h(AppLayout)
}).$mount('#app')
