<template>
  <div class="login_link">
    <h1 v-if="err">{{ msg }}</h1>
  </div>
</template>
<script>
export default {
  name: 'Login_link',
  data() {
    return {
      err: false,
      msg: 'Ошибка авторизации'
    }
  },
  mounted() {
    this.$store
      .dispatch('user/LOGIN_LINK', this.$route.params.user)
      .then(() => {
        this.$router.replace({ path: '/' })
      })
      .catch(() => {
        this.err = true
      })
  }
}
</script>

<style lang="scss" scoped>
.login_link {
  h1 {
    font-size: 20px;
    text-align: center;
    padding: 30px 20px;
  }
}
</style>
