import CategoriesService from '@/services/categories.service'

export default {
  namespaced: true,
  state: {
    byId: new Map(),
    byAlias: new Map(),
    ageAlias: 'all'
  },

  mutations: {
    SET_PAGE(state, { id, alias, details }) {
      state.byId = new Map(state.byId).set(id, details)
      state.byAlias = new Map(state.byAlias).set(alias, details)
    },
    SET_AGE_ALIAS(state, { alias }) {
      state.ageAlias = alias
    }
  },

  actions: {
    getPage({ commit, rootState }, alias) {
      const id = rootState.categories.childrenByAlias.get(alias)?.id

      CategoriesService.getPageDetails(id)
        .then(details => commit('SET_PAGE', { id, alias, details }))
        .catch(error =>
          commit(
            'toast/NEW',
            { type: 'error', message: error.message },
            { root: true }
          )
        )
    },
    setAgeAlias({ commit }, alias) {
      commit('SET_AGE_ALIAS', { alias })
    }
  }
}
