<template>
  <el-autocomplete
    class="ui-header-search"
    placeholder="Быстрый поиск по Анализам"
    suffix-icon="el-icon-search"
    v-model="input"
    :trigger-on-focus="false"
    :fetch-suggestions="querySearch"
    @select="handleSelect"
  ></el-autocomplete>
</template>

<script>
export default {
  name: 'UiHeaderSearch',
  data() {
    return {
      input: '',
      links: []
    }
  },

  methods: {
    querySearch(queryString, cb) {
      const links = this.links
      const results = queryString
        ? links.filter(this.createFilter(queryString))
        : links
      cb(results)
    },
    createFilter(queryString) {
      return link => {
        return (
          link.value?.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
        )
      }
    },
    handleSelect(item) {
      this.$router.replace(`/guide/${item.alias}`)
      this.input = ''
    },
    setLinks() {
      const result = this.$store.state.categories.all.map(c => ({
        ...c,
        value: c.title
      }))
      let childrens = []

      result.forEach(c => {
        childrens = childrens.concat(
          c.children.map(child => ({
            ...child,
            value: child.sub_title || child.title,
            alias: `${c.alias}/${child.alias}`
          }))
        )
      })

      this.links = result.concat(childrens)
    }
  },

  watch: {
    '$store.state.categories': {
      handler: function() {
        this.setLinks()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.ui-header-search {
  .el-input__inner {
    height: 50px;
    line-height: 50px;
    border: 1px solid #eff3f8;
    padding-left: 20px;
    padding-right: 40px;
  }

  .el-input__inner::placeholder,
  .el-input__suffix {
    color: #b4cab3;
  }

  .el-input__suffix {
    right: 15px;
  }
}

@media only screen and (max-width: $mobile-max-width) {
  .ui-header-search {
    width: 100%;

    .el-input__inner {
      height: 40px;
      line-height: 40px;
      padding-left: 13px;
      padding-right: 30px;
    }

    .el-input__suffix {
      right: 8px;
    }
  }
}
</style>

<style scoped lang="scss">
.ui-header-search {
  flex: 1;
}
</style>
