<template>
  <div v-show="increaseHtml || decreaseHtml">
    <div class="controls">
      <UiButton
        title="Причины понижения"
        class="button"
        v-show="decreaseHtml"
        :active="switcher"
        @click.native="onSwitch(true)"
      />
      <UiButton
        title="Причины повышения"
        class="button"
        v-show="increaseHtml"
        :active="!switcher"
        @click.native="onSwitch(false)"
      />
    </div>
    <div class="html-container" v-if="switcher" v-html="decreaseHtml"></div>
    <div class="html-container" v-if="!switcher" v-html="increaseHtml"></div>
  </div>
</template>

<script>
import UiButton from './UiButton'

export default {
  name: 'UiReasons',

  components: {
    UiButton
  },

  props: {
    decreaseHtml: {
      type: String,
      default: ''
    },
    increaseHtml: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      switcher: !!this.decreaseHtml
    }
  },

  methods: {
    onSwitch: function(state) {
      this.switcher = state
    }
  }
}
</script>

<style lang="scss" scoped>
.controls {
  margin-left: -5px;
  margin-right: -5px;

  .button {
    margin: 5px;
  }
}
</style>

<style lang="scss">
.html-container {
  margin-top: 20px;

  div,
  ul {
    margin: 25px 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #383838;
    display: flex;
    flex-wrap: wrap;

    li {
      padding-left: 1em;
      text-indent: -0.7em;
      width: 50%;
      margin-bottom: 14px;

      div {
        display: inline;
      }
    }

    li:before {
      content: '';
      display: inline-block;
      background-color: #4ebd7a;
      margin-right: 8px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }
  }
}

@media only screen and (max-width: $mobile-max-width) {
  .html-container {
    ul {
      li {
        width: 100%;
      }
    }
  }
}
</style>
