<template>
  <div class="ui-container wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UiContainer'
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.ui-container {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  margin-bottom: 25px;
  align-items: flex-start;
}

@media only screen and (max-width: $mobile-max-width) {
  .ui-container {
    margin: 0;
    padding: 0;
  }
}
</style>
