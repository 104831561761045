import homePage from '../pages/Home.vue'
import notFoundPage from '../pages/NotFound.vue'
import Login_link from '../pages/Login_link.vue'
import Login from '../pages/Login.vue'
import AccessDenied from '../pages/AccessDenied.vue'
import SubscriptionError from '@/pages/SubscriptionError.vue'

export const routes = [
  {
    path: '/guide',
    redirect: '/',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    component: homePage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/guide/:page',
    name: 'PageDetailCat',
    component: homePage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/guide/:page/:details',
    name: 'PageDetail',
    component: homePage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bookmarks',
    component: homePage,
    props: { isBookmarks: true },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bookmarks/:page',
    component: homePage,
    props: { isBookmarks: true },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bookmarks/:page/:details',
    component: homePage,
    props: { isBookmarks: true },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/auth/reglink/:user',
    component: Login_link
  },
  {
    path: '/login',
    component: Login,
    props: { isHideHeader: true }
  },
  {
    path: '/403',
    component: AccessDenied,
    meta: {
      title: 'Доступ запрещен'
    }
  },
  {
    path: '/402',
    component: SubscriptionError,
    meta: {
      title: 'Срок подписки истёк'
    }
  },
  {
    path: '*',
    component: notFoundPage,
    meta: {
      title: 'Страница не найдена',
      requiresAuth: true
    }
  }
]
