<template>
  <div id="app">
    <AppHeader v-if="token"></AppHeader>
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import AppHeader from './Header.vue'
import { mapState } from 'vuex'
export default {
  name: 'AppLayout',
  components: {
    AppHeader
  },
  computed: {
    ...mapState({
      token: state => state.user.token
    })
  },
  mounted() {
    if (!this.token && this.$route.path.indexOf('/login') !== 0) {
      const queryUrl = this.$route.path
      console.log(queryUrl)
      this.$router.replace({ path: '/login', query: { url: queryUrl } })
    }
  }
}
</script>
