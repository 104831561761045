import router from '@/router'

export default class ErrorService {
  static subscriptionErrorRedirect() {
    router.push('/402')
  }

  static deniedErrorRedirect() {
    router.push('/403')
  }
}
