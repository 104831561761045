<template>
  <el-button
    :class="
      `ui-button component ${size}${active ? ' active' : ''}${
        primary ? ' primary' : ''
      }`
    "
    :loading="loading"
    :native-type="nativeType"
  >
    {{ title }}
    <i
      v-if="icon"
      class="el-icon-arrow-down el-icon--right"
      v-bind:class="icon"
    ></i>
  </el-button>
</template>

<script>
export default {
  name: 'UiButton',
  props: {
    nativeType: {
      type: String,
      default: 'Button'
    },
    title: {
      type: String,
      default: 'Button'
    },
    active: {
      type: Boolean,
      default: true
    },
    primary: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String
    },
    size: {
      type: String,
      default: 'large'
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.ui-button {
  border-radius: 5px;
  color: #b4cab3;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid rgba(180, 202, 179, 0.3);
  padding: 0 20px;

  &.active {
    background-color: #effbef;
  }

  &.primary {
    border: none;
    background-color: #effbef;
  }

  &.primary.active {
    background-color: #4ebd7a;
    color: #fff;
  }

  &.large {
    height: 40px;
  }

  &.extra-large {
    height: 54px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    padding: 0 24px;
  }
}

@media only screen and (max-width: $mobile-max-width) {
  .ui-button {
    &.large {
      height: 34px;
    }

    &.extra-large {
      height: 44px;
    }
  }
}
</style>
