<template>
  <div class="login-layout">
    <div class="logo"></div>
    <el-form @submit.native="onSubmit" ref="loginForm" class="form">
      <h1>Вход</h1>
      <div class="content">
        <div class="label" :class="{ error }">
          {{ error ? 'Неправильный e-mail/телефон:' : 'E-mail/Телефон:' }}
        </div>
        <UiInput
          v-model="username"
          placeholder="Karandashevich2000@yandex.ru"
          :error="error"
        />
        <div class="label" :class="{ error }">
          {{ error ? 'Неправильный пароль:' : 'Пароль:' }}
        </div>
        <UiInput
          type="password"
          v-model="password"
          placeholder="••••••"
          :error="error"
        />
      </div>
      <div class="controls">
        <UiButton
          nativeType="submit"
          title="Войти"
          size="extra-large"
          :loading="loading"
          @click.native="onSubmit"
        />

        <router-link v-if="false" class="forgot" to="/login"
          >Забыли пароль?</router-link
        >
      </div>
    </el-form>
    <div class="rights">Все права защищены Nutrition Science Academy ©</div>
  </div>
</template>

<script>
import UiButton from '@/components/UiButton.vue'
import UiInput from '@/components/UiInput.vue'

export default {
  name: 'Login',

  components: {
    UiButton,
    UiInput
  },

  data() {
    return {
      username: '',
      password: '',
      error: false,
      loading: false
    }
  },
  watch: {
    username(newValue, oldValue) {
      if (newValue !== oldValue) this.onChangeValue('username', newValue)
    },
    password(newValue, oldValue) {
      if (newValue !== oldValue) this.onChangeValue('password', newValue)
    }
  },
  methods: {
    onChangeValue(propName, value) {
      this[propName] = value
    },
    onSubmit() {
      const username = this.username
      const password = this.password
      this.loading = true
      this.$store.dispatch('user/signIn', { username, password }).then(res => {
        if (res && !res.isAxiosError) {
          const redirectUrl = this.$route.query.url
          redirectUrl
            ? this.$router.replace(redirectUrl)
            : this.$router.replace('/')
        } else {
          this.error = true
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.login-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  background-image: url(../assets/login-background.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 30px 15px;
  box-sizing: border-box;

  .logo {
    background-image: url(../assets/logo-large.svg);
    background-size: contain;
    width: 108px;
    height: 54px;
    background-repeat: no-repeat;
    margin-bottom: 68px;
  }

  .form {
    width: 100%;
    max-width: 524px;
    padding: 44px 38px 50px;
    background: #ffffff;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
    border-radius: 10px;

    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 26px;
      color: #272727;
    }

    .content {
      margin-top: 22px;
      margin-bottom: 32px;

      .label {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        color: #c7c7c7;
        margin-bottom: 9px;
        margin-top: 13px;

        &.error {
          color: $color-error;
        }
      }
    }

    .controls {
      .forgot {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #4ebd7a;
        margin-left: 26px;
      }
    }
  }

  .rights {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #439b66;
    margin-top: 60px;
  }
}

@media only screen and (max-width: $mobile-max-width) {
  .login-layout {
    .form {
      padding: 44px 28px 50px;

      h1 {
        font-size: 28px;
      }
      .content {
        .label {
          font-size: 14px;
        }
      }
      .controls {
        .forgot {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
