<template>
  <div>
    <div class="title">
      <UiBackButton v-show="showBackButton" />
      <span>{{ title }} </span>
    </div>
    <p class="subTitle" v-show="subTitle">{{ subTitle }}</p>
  </div>
</template>

<script>
import UiBackButton from './UiBackButton.vue'

export default {
  name: 'UiTitle',

  components: {
    UiBackButton
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    indexStandart: {
      type: Number,
      default: 0
    }
  },

  computed: {
    isDesktop() {
      return this.$store.getters['dom/isDesktop']
    },
    showBackButton() {
      if (this.$route.name === 'PageDetail' && this.indexStandart === 0)
        return true
      else if (
        this.$route.name === 'PageDetailCat' &&
        !this.isDesktop &&
        this.indexStandart === 0
      )
        return true
      else return false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.title {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 110%;
  color: #333c4d;
  margin-bottom: 17px;
  display: flex;
  align-items: center;
}
.subTitle {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 14px;
  margin-top: -5px;
  line-height: 1.3;
}
@media only screen and (max-width: $mobile-max-width) {
  .title {
    font-size: 22px;
  }
}
</style>
