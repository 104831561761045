import axios from 'axios'
import ErrorService from '@/services/error.service'

import { API_URL } from '../.env'

export default class Http {
  constructor(entity, withAuth) {
    this.withAuth = withAuth
    this.instance = axios.create({
      baseURL: `${API_URL}/`
    })
    return this.init()
  }

  init() {
    if (this.withAuth) {
      this.instance.interceptors.request.use(
        request => {
          // TODO: Auth logic
          const token = localStorage.getItem('token')
          request.headers.Authorization = `Bearer ${token}`
          return request
        },
        error => {
          return Promise.reject(error)
        }
      )
      this.instance.interceptors.response.use(
        response => {
          return Promise.resolve(response)
        },
        error => {
          if(error.response.status === 402) {
            ErrorService.subscriptionErrorRedirect()
          }
          if(error.response.status === 403) {
            ErrorService.deniedErrorRedirect()
          }
          return Promise.reject(error)
        }
      )
    }
    return this.instance
  }
}
