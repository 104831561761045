<template>
  <div class="reasons" v-show="!!items.length">
    <div class="controls">
      <UiButton
        class="button"
        title="Референсные значения"
        :active="switcher"
        @click.native="onSwitchVals(true)"
      />
      <UiButton
        v-show="hasOptimum"
        class="button"
        title="Оптимум"
        :active="!switcher"
        @click.native="onSwitchVals(false)"
      />
    </div>

    <div class="units">
      <UiButton
        class="button sm-hide"
        v-for="unit in units"
        :title="unit.name"
        :active="unit.name === selectedUnits"
        :key="unit.name"
        :primary="false"
        @click.native="onSwitchUnits(unit.name)"
      />
      <el-dropdown trigger="click" v-if="units.length > 0">
        <UiButton
          class="dropdown-button sm-show"
          :title="selectedUnits"
          active
          :primary="false"
          icon="el-icon-arrow-down"
        />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="unit in units"
            v-bind:key="unit.name"
            class="ui-standards__units-dropdown-item"
            @click.native="onSwitchUnits(unit.name)"
          >
            {{ unit.name }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    
    <div class="grid">
      <div class="cell" v-for="item in itemsByAge" :key="item.id">
        <UiStandard
          :item="item"
          :units="units"
          :selectedUnits="selectedUnits"
          :isNorm="switcher"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from './UiButton'
import UiStandard from './UiStandard'

export default {
  name: 'UiStandards',

  components: {
    UiButton,
    UiStandard
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    units: {
      type: Array,
      default: () => []
    },
    defaultUnit: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      switcher: true,
      selectedUnits: this.defaultUnit
    }
  },

  mounted() {},

  computed: {
    hasOptimum() {
      const optimum = ['optimum_from', 'optimum_text', 'optimum_to']
      return this.items.some(function(el) {
        return optimum.some(o => el[o] !== null)
      })
    },
    itemsByAge() {
      const alias = this.$store.state.pages.ageAlias
      return alias === 'all'
        ? this.items
        : this.items.filter(i => i.age_categories.some(a => a.alias === alias))
    }
  },

  methods: {
    onSwitchVals: function(state) {
      this.switcher = state
    },
    onSwitchUnits: function(unitName) {
      this.selectedUnits = unitName
      if (window.document.activeElement?.blur) {
        window.document.activeElement.blur()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.reasons {
  margin-bottom: 35px;

  .controls {
    margin-bottom: 30px;
  }

  .units {
    margin-bottom: 4px;
  }

  .controls,
  .units {
    margin-left: -5px;
    margin-right: -5px;
  }

  .button {
    margin: 5px;
  }

  .dropdown-button {
    width: 100%;
    text-align: left;
  }
}
</style>

<style lang="scss">
.ui-standards__units-dropdown-item {
  line-height: 50px;
}
.el-dropdown-menu.el-popper[x-placement^='bottom'] {
  left: 15px !important;
  right: 15px;
}
.reasons {
  .el-dropdown {
    width: 100%;
    box-sizing: border-box;
    padding: 0 5px;

    .dropdown-button {
      span {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
</style>
