import BaseService from './base.service'

export default class BookmarksService extends BaseService {
  static get entity() {
    return 'bookmarks'
  }

  static async getBookmarks(userId) {
    try {
      const response = await this.request(true).get(
        `nsa_plus/analysis/categories/getfavorite/${userId}`
      )
      this.assertArray(response.data, 'Invalid "bookmarks" response')
      return response.data.filter(d => d.is_checked)
    } catch (error) {
      this.throwError(error)
    }
  }

  static async addBookmarks(userId, categoryId) {
    try {
      const response = await this.request(true).get(
        `nsa_plus/analysis/categories/addfavorite/${userId}/${categoryId}`
      )
      return response.data
    } catch (error) {
      this.throwError(error)
    }
  }

  static async removeBookmarks(userId, categoryId) {
    try {
      const response = await this.request(true).get(
        `nsa_plus/analysis/categories/removefavorite/${userId}/${categoryId}`
      )
      return response.data
    } catch (error) {
      this.throwError(error)
    }
  }
}
