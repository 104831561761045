import CategoriesService from '@/services/categories.service'

export default {
  namespaced: true,
  state: {
    all: [],
    byAlias: new Map(),
    childrenByAlias: new Map(),
    loaded: false,
    isBlocked: false,
  },

  mutations: {
    SET_CATEGORIES(state, value) {
      state.all = [...value]
      state.byAlias = new Map(state.byAlias)
      state.childrenByAlias = new Map(state.childrenByAlias)

      value.forEach(item => {
        state.byAlias.set(item.alias, item)
        if (Array.isArray(item.children)) {
          item.children.forEach(child =>
            state.childrenByAlias.set(child.alias, child)
          )
        }
      })

      state.loaded = true
    },
    SET_IS_BLOCKED(state, value) {
      state.isBlocked = value;
    }
  },

  getters: {
    all: state => state.all,
    adult: state => state.all.filter(() => true),
    kids: state => state.all.filter(() => true),
    bookmarks: (state, selectors, { bookmarks }) =>
      state.all.filter(({ id }) => bookmarks.idsSet.has(id))
  },

  actions: {
    getCategories({ commit }) {
      CategoriesService.getAllCategories()
        .then(data => {
          commit('SET_CATEGORIES', data.items)
          commit('SET_IS_BLOCKED', data.is_blocked)
        })
        .catch(error =>
          commit(
            'toast/NEW',
            { type: 'error', message: error.message },
            { root: true }
          )
        )
    }
  }
}
