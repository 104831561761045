<template>
  <header
    class="header"
    v-bind:class="{ hide: shouldHideHeaderInMobile }"
    v-show="!isHideHeader"
  >
    <UiToastList />

    <div class="wrapper">
      <router-link :to="{ path: '/' }" class="flex">
        <div class="logo"></div>
        <div class="title">Академия<br />Нутрициологии</div>
      </router-link>
      <div class="flex-control">
        <div class="nav">
          <UiHeaderNavButton title="Закладки" :path="bookmarksPath" />
          <UiHeaderNavButton title="Все" :path="guidePath" />
        </div>
        <UiHeaderSearch />
      </div>
    </div>
  </header>
</template>

<script>
import UiToastList from '@/components/UiToastList'
import UiHeaderNavButton from '@/components/UiHeaderNavButton.vue'
import UiHeaderSearch from '@/components/UiHeaderSearch.vue'

export default {
  name: 'Header',
  components: {
    UiToastList,
    UiHeaderNavButton,
    UiHeaderSearch
  },
  computed: {
    pageName: function() {
      return this.$route.params?.page
    },
    detailsPageName: function() {
      return this.$route.params?.details
    },
    shouldHideHeaderInMobile() {
      return this.$route.params?.page || this.$route.params?.details
    },
    path: function() {
      let path = ''
      if (this.pageName) {
        path += '/' + this.pageName
      }
      if (this.detailsPageName) {
        path += '/' + this.detailsPageName
      }
      return path
    },
    bookmarksPath: function() {
      return `/bookmarks${this.path}`
    },
    guidePath: function() {
      return this.path ? `/guide${this.path}` : '/'
    },
    isHideHeader: function() {
      return this.$route.path.indexOf('/login') === 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.header {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 21px 0;

  .wrapper,
  .flex,
  .flex-control {
    display: flex;
    align-items: center;
  }

  .flex-control {
    flex: 1;
  }

  .logo {
    background-image: url(../assets/logo.svg);
    background-size: contain;
    width: 60px;
    background-repeat: no-repeat;
    height: 30px;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #383838;
    margin-left: 11px;
  }

  .nav {
    margin: 0 23px;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: $mobile-max-width) {
  .header {
    padding: 15px 0;
    border-bottom: 1px solid #ecf1f7;

    &.hide {
      display: none;
    }

    .wrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    .logo {
      width: 44px;
      height: 22px;
    }

    .title {
      font-size: 12px;
      margin-left: 8px;
    }

    .flex-control {
      flex-direction: column-reverse;
      align-items: flex-start;
      width: 100%;
      margin-top: 18px;

      .nav {
        margin-left: 0;
        margin-right: 0;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
</style>
