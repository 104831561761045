import Vue from 'vue'
import Router from 'vue-router'

import { routes } from './routes'

Vue.use(Router)

const router = new Router({
  linkActiveClass: 'is-active',
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta?.title ?? false) document.title = to.meta.title
  next()
})

export default router
