<template>
  <div class="item">
    <div class="icons">
      <img
        v-for="icon in item.icons"
        :src="icon.path || defaultTeenImage"
        :key="icon.id"
      />
    </div>
    <span>
      <b>{{ item.description }}</b>
    </span>
    <span>{{ volume }}</span>
    <span class="green">
      <b v-html="range"></b>
    </span>
    <span v-show="item.annotation" class="anotantion">
      {{ item.annotation }}
    </span>
  </div>
</template>

<script>
import defaultTeenImage from "@/assets/teen.svg";

export default {
  name: 'UiStandard',

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isNorm: {
      type: Boolean,
      default: true
    },
    units: {
      type: Array,
      default: () => []
    },
    selectedUnits: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      defaultTeenImage
    }
  },

  computed: {
    volume() {
      return this.selectedUnits || ''
    },
    range() {
      const ratio = this.units.find(u => u.name === this.selectedUnits)?.ratio
      let from = this.isNorm ? this.item.norm_from : this.item.optimum_from
      let to = this.isNorm ? this.item.norm_to : this.item.optimum_to
      if (typeof ratio === 'number') {
        if (typeof from === 'string') {
          from = +from
          from *= ratio
        }
        if (typeof to === 'string') {
          to = +to
          to *= ratio
        }
      }

      const text = this.isNorm ? this.item.norm_text : this.item.optimum_text

      const formattedVal = val => {
        const deg = +String(Math.round(val)).length - 1
        return {newVal: (val / (10 ** deg)).toFixed(2), deg}
      }

      const parsedValue = v => {
        let val = v || 0
        val = +val
        // if (val > 1000000000000) {
        //   // trillion
        //   return `${(val / 1000000000000).toLocaleString()} трлн.`
        // } else if (val > 1000000000) {
        //   // billion
        //   return `${(val / 1000000000).toLocaleString()} млрд.`
        // } else if (val > 1000000) {
        //   // million
        //   return `${(val / 1000000).toLocaleString()} млн.`
        // }
        if (+val > 9999) {
          const {newVal, deg} = formattedVal(val)
          return `${String(newVal).replace('.', ',')} * 10 <sup class="degree">${deg}</sup>`
        } else {
          return (+val).toLocaleString()
        }
      }

      if (text?.length > 0) {
        const f = parsedValue(from)
        return `${text} ${f === '0' ? '' : f}`
      }
      if (typeof from === 'number') {
        if (typeof to === 'number') {
          const fv = parsedValue(from)
          const tv = parsedValue(to)
          return `${fv} - ${tv}`
        }
      }
      const r = parsedValue(to)
      return r === '0' ? '' : r
    }
  }
}
</script>

<style lang="scss">
.degree {
  font-size: 8px !important;
  vertical-align: super !important;
}
</style>

<style scoped lang="scss">
@mixin z-index-reverse {
  @for $i from 1 through 4 {
    &:nth-child(#{$i}) {
      z-index: #{5 - $i};
    }
  }
}

.item {
  border: 1px solid #eff3f8;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .icons {
    display: flex;
    align-items: center;

    img {
      @include z-index-reverse;
      margin-bottom: 11px;
      width: 50px;
      max-height: 50px;
      background-size: contain;
      background-position: center;
    }

    img + img {
      margin-left: -23px;
    }
  }

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;
    text-align: center;
    color: #383838;
    margin-bottom: 9px;

    &.green {
      color: #4ebd7a;
      margin-top: 2px;
      margin-bottom: 0;
    }

    &.anotantion {
      margin-top: 13px;
      margin-bottom: 0;
      font-size: 10px;
    }

    b {
      font-weight: bold;
    }
  }
}
</style>
