<template>
  <div class="ui-sidebar" v-bind:class="{ hide: shouldHideSidebarInMobile }">
    <el-menu v-if="isLoaded">
      <el-menu-item
        v-for="item in items"
        :key="item.alias"
        class="item"
        v-bind:class="{
          active: current && current.alias === item.alias,
          favorite: getIsFavorite(item),
          lock: !item.is_checked,
          'lock-red': !isBlocked
        }"
        @click="onItemClick(item)"
      >
        <el-button
          class="star-button"
          v-bind:class="{ lock: !item.is_checked, 'lock-red': isBlocked }"
          :icon="item.is_checked & !isBlocked ? 'el-icon-star-on' : ''"
          circle
        />
        <span class="title">{{ item.title }}</span>
        <i class="el-icon-arrow-right"></i>
      </el-menu-item>
    </el-menu>
    <div class="no-bookmarks" v-if="isLoaded && isBookmarks && !items.length">
      <div>Закладки отсутствуют</div>
      Чтобы добавить анализ в закладки, нажмите на индикатор слева от названия
      анализа.
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UiSidebar',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    basePath: {
      type: String,
      default: '/'
    },
    current: {
      type: Object
    },
    isBookmarks: {
      type: Boolean
    },
    isLoaded: {
      type: Boolean
    }
  },
  computed: {
    shouldHideSidebarInMobile() {
      return this.$route.params?.page || this.$route.params?.details
    },
    ...mapState('categories', {
      isBlocked: state => state.isBlocked
    })
  },
  methods: {
    getIsFavorite(item) {
      return this.$store.state.bookmarks.idsSet.has(item.id)
    },
    onItemClick(item) {
      if(this.isBlocked) return
      this.$router.push(`${this.basePath}/${item.alias}`).catch(e => {})
    },
    onStarClick(event, item) {
      event.stopPropagation()
      event.preventDefault()
      const userId = this.$store.state.user.user_id
      const categoryId = item.id
      if (this.getIsFavorite(item)) {
        this.$store.dispatch('bookmarks/remove', { userId, categoryId })
      } else {
        this.$store.dispatch('bookmarks/add', { userId, categoryId })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.ui-sidebar {
  width: 407px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  margin-right: 15px;
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 142px);
  top: 117px;
  position: sticky;

  .no-bookmarks {
    padding: 20px;
    align-items: center;
    justify-content: center;
    line-height: 24px;
  }

  .el-menu {
    border: none;
  }

  .item {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    color: #383838;
    padding: 12px 18px !important;
    height: inherit;

    &.active {
      color: #4ebd7a;
    }

    &.lock {
      pointer-events: none;
    }

    .star-button {
      background-color: transparent;
      border: none;
      padding: 0;
      height: 18px;
      width: 18px;

      &.lock {
        background: url(../assets/lock.svg);
        width: 18px;
        height: 18px;
        background-size: 9.5px;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.lock-red {
        background: url(../assets/lock-red.svg);
        width: 18px;
        height: 18px;
        background-size: 9.5px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .title {
      white-space: normal;
      margin-left: 13px;
      line-height: 1.5;
      width: calc(100% - 13px - 18px);
    }

    .el-icon-arrow-right {
      color: #efefef;
      display: none;
      margin-right: -2px;
      width: 18px;
      height: 18px;
    }
  }

  .item + .item {
    border-top: 1px solid rgba(76, 173, 115, 0.05);
    transition: none;
  }

  .item:first-child {
    padding-top: 18px !important;
  }

  .item:last-child {
    padding-bottom: 18px !important;
  }
}

.ui-sidebar::-webkit-scrollbar-thumb {
  background: #effbef;
  border-radius: 4px;
}

.ui-sidebar::-webkit-scrollbar-thumb:hover {
  background: #4ebd7a;
}

.ui-sidebar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.ui-sidebar::-webkit-scrollbar-track {
  background: transparent;
}

@media only screen and (max-width: $mobile-max-width) {
  .ui-sidebar {
    position: unset;
    width: 100%;
    margin-right: 0;
    overflow-y: hidden;
    max-height: none;
    margin: 15px;

    &.hide {
      display: none;
    }

    .item {
      font-size: 14px;

      .el-icon-arrow-right {
        display: block;
      }

      .title {
        width: calc(100% - 13px - 18px - 16px);
      }
    }
  }
}
</style>

<style lang="scss">
.ui-sidebar {
  .item .star-button .el-icon-star-on {
    color: #efefef;
    margin: 0;
    width: 18px;
    height: 18px;
  }

  .item.favorite .star-button .el-icon-star-on {
    color: #4ebd7a;
  }
}
</style>
