<template>
  <div class="ui-body component" v-bind:class="{ hide: shouldHideInMobile }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UiBody',
  computed: {
    shouldHideInMobile() {
      return !this.$route.params?.page && !this.$route.params?.details
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.ui-body {
  display: flex;
  flex: 1;
  background-color: #fff;
  border-radius: 5px;
  margin-left: auto;
}

@media only screen and (max-width: $mobile-max-width) {
  .ui-body {
    padding: 15px;
    min-height: 100vh;

    &.hide {
      display: none;
    }
  }
}
</style>
