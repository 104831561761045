<template>
  <div class="error">
    <h1>Срок подписки истек</h1>
    <p>Обратитесь в отдел продаж</p>
  </div>
</template>

<script>
export default {
  name: 'AccessDenied'
}
</script>

<style lang="scss" scoped>
.error {
  h1 {
    font-size: 20px;
    text-align: center;
    padding: 42px 36px;
  }
  p {
    font-size: 14px;
    color: gray;
    text-align: center;
  }
}
</style>
