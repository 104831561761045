import dom from './dom'
import toast from './toast'
import categories from './categories'
import pages from './pages'
import bookmarks from './bookmarks'
import user from './user'

export default {
  dom,
  toast,
  categories,
  pages,
  bookmarks,
  user
}
