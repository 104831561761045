import BaseService from './base.service'

export default class AuthService extends BaseService {
  /**
   * Get User Token
   * @param {string} userId
   * @returns {object[]}
   */
  static async loginLinkAuth(userId) {
    try {
      const response = await this.request().get(`/users/${userId}`)
      this.loginUser(response.data)
      return response.data
    } catch (error) {
      this.logoutUser()
      this.throwError(error)
    }
  }

  static async signIn(username, password) {
    try {
      const response = await this.request().post(`/login`, { username, password })
      this.loginUser(response.data)
      return response.data
    } catch (error) {
      return error
    }
  }
}
