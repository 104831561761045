<template>
  <div
    class="ui-page-nav-cards component"
    v-bind:class="{ hide: items.length === 0 }"
  >
    <UiTitle class="title" :title="title" />
    <div v-show="anotation" v-html="anotation" class="anotation"></div>
    <div class="grid">
      <div class="cell" v-for="item in items" :key="item.alias">
        <router-link :to="{ path: `${basePath}/${item.alias}` }" class="item">
          <div class="cell_img">
            <img :src="item.icon || defaultCategoryImage" />
          </div>
          <span>{{ item.title }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import defaultCategoryImage from '@/assets/category.svg'
import UiTitle from '@/components/UiTitle.vue'

export default {
  name: 'UiPageNavCards',

  components: {
    UiTitle
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    basePath: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    anotation: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      defaultCategoryImage
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.ui-page-nav-cards {
  width: 100%;
  height: max-content;
  padding: 20px;

  .cell_img {
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: center;
  }

  .anotation {
    margin-bottom: 20px;
    line-height: 20px;
  }
  .item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: all 50ms;

    img {
      max-height: 100px;
      background-size: contain;
      background-position: center;
    }

    span {
      text-align: center;
      margin-top: 21px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 110%;
      color: #383838;
      max-width: 100%;
      overflow-wrap: break-word;
    }
  }

  .item:hover {
    span {
      color: #4ebd7a;
    }
  }
}

.hide {
  display: none;
}

@media only screen and (max-width: $mobile-max-width) {
  .grid .cell {
    margin-bottom: 15px;
  }
  .hide {
    display: initial;
  }
  .ui-page-nav-cards {
    padding: 0;

    .title {
      display: flex;
    }
    .item {
      span {
        font-size: 14px;
      }
    }
  }
}
</style>
