import BookmarksService from '@/services/bookmarks.service'

export default {
  namespaced: true,
  state: {
    all: [],
    idsSet: new Set(),
    loaded: false
  },

  mutations: {
    SET_BOOKMARKS(state, value) {
      state.all = [...value]
      state.idsSet = new Set(value.map(({ id }) => id))
      state.loaded = true
    },
    ADD_BOOKMARK(state, value) {
      if (!state.idsSet.has(value.id)) {
        state.all = [...state.all, value]
        state.idsSet = new Set(state.idsSet)
        state.idsSet.add(value.id)
      }
    },
    REMOVE_BOOKMARK(state, value) {
      if (state.idsSet.has(value.id)) {
        state.all = state.all.filter(({ id }) => id !== value.id)
        state.idsSet = new Set(state.idsSet)
        state.idsSet.delete(value.id)
      }
    }
  },

  actions: {
    getBookmarks({ commit }, userId) {
      BookmarksService.getBookmarks(userId)
        .then(bookmarks => commit('SET_BOOKMARKS', bookmarks))
        .catch(error =>
          commit(
            'toast/NEW',
            { type: 'error', message: error.message },
            { root: true }
          )
        )
    },
    add({ commit }, payload) {
      commit('ADD_BOOKMARK', { id: payload.categoryId })
      BookmarksService.addBookmarks(payload.userId, payload.categoryId)
        .then(() => {})
        .catch(error => {
          commit('REMOVE_BOOKMARK', { id: payload.categoryId })
          commit(
            'toast/NEW',
            { type: 'error', message: error.message },
            { root: true }
          )
        })
    },
    remove({ commit }, payload) {
      commit('REMOVE_BOOKMARK', { id: payload.categoryId })
      BookmarksService.removeBookmarks(payload.userId, payload.categoryId)
        .then(() => {})
        .catch(error => {
          commit('ADD_BOOKMARK', { id: payload.categoryId })
          commit(
            'toast/NEW',
            { type: 'error', message: error.message },
            { root: true }
          )
        })
    }
  }
}
