import BaseService from './base.service'

export default class CategoriesService extends BaseService {
  static get entity() {
    return 'categories'
  }

  static async getAllCategories() {
    try {
      const response = await this.request(true).get(
        'nsa_plus/analysis/categories'
      )
      this.assertArray(response.data.items, 'Invalid "categories" response')
      return response.data
    } catch (error) {
      this.throwError(error)
    }
  }

  /**
   * Get Page Details
   * @param {number} id - detals category id
   * @returns {object[]}
   */
  static async getPageDetails(id) {
    this.assertNumber(id, `Invalid page details id "${JSON.stringify(id)}"`)

    try {
      const response = await this.request(true).get(
        `nsa_plus/analysis/categories/${id.toString()}`
      )
      this.assertArray(response.data, 'Invalid "categories" response')
      return response.data
    } catch (error) {
      this.throwError(error)
    }
  }
}
