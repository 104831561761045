<template>
  <el-input
    class="ui-control-input"
    v-bind:class="{ error }"
    v-model="model"
    :type="type"
    :show-password="showPassword"
    :placeholder="placeholder"
    @blur="blur"
  >
  </el-input>
</template>

<script>
export default {
  name: 'UiInput',

  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    showPassword: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      model: this.value
    }
  },
  methods: {
    blur() {
      this.model = this.model.trim()
    }
  },

  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.model = newVal
    },
    model(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit('input', newVal)
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/_variables.scss';

.ui-control-input {
  .el-input__inner {
    height: 50px;
    line-height: 50px;
    border: 1px solid #eff3f8;
    padding-left: 20px;
    padding-right: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
  }

  .el-input__inner::placeholder {
    color: #b4cab3;
  }

  &.error {
    .el-input__inner {
      color: $color-error;
      border-color: $color-error;
    }
  }
}

@media only screen and (max-width: $mobile-max-width) {
  .ui-control-input {
    .el-input__inner {
      font-size: 16px;
      padding-left: 12px;
      padding-right: 12px;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>
