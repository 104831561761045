import UserService from '@/services/auth.service'

export default {
  namespaced: true,
  state: {
    user_name: localStorage.getItem('user_name') || '',
    user_id: localStorage.getItem('user_id') || '',
    token: localStorage.getItem('token') || ''
  },
  mutations: {
    SET_USER(state, user) {
      state.user_name = user.user_name
      state.user_id = user.user_id
      state.token = 'Bearer ' + user.token
    },
    REMOVE_USER(state) {
      state.user_name = ''
      state.user_id = ''
      state.token = ''
    }
  },
  actions: {
    signIn({ commit }, payload) {
      return new Promise((resolve, reject) => {
        UserService.signIn(payload.username, payload.password)
          .then(user => {
            commit('SET_USER', user)
            resolve(user)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    LOGIN_LINK({ commit }, payload) {
      return new Promise((resolve, reject) => {
        UserService.loginLinkAuth(payload)
          .then(user => {
            commit('SET_USER', user)
            resolve()
          })
          .catch(error => {
            commit('REMOVE_USER')
            reject(error)
          })
      })
    }
  }
}
