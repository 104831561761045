import Http from './http'
import router from '../router/index'

class HttpAssertionError extends Error {
  constructor(message) {
    super(message)
    this.message = message || 'http assertion error'
    this.code = 'HTTP_ASSERTION_ERROR'
  }
}

class HttpError extends Error {
  constructor(error, message) {
    super()
    this.status = error?.response?.status ?? false
    this.message = message || 'http error'
    this.code = 'HTTP_ERROR'
    if (this.status === 401) {
      new User().removeUser()
      console.log(window.location.pathname)
      router.replace({ path: '/login' }).catch(e => {})
    }
  }
}

class User {
  constructor(data) {
    data = data || {}
    this.token = data.token
    this.user_name = data.user_name
    this.id = data.user_id
  }

  setUser() {
    localStorage.setItem('token', this.token)
    localStorage.setItem('user_name', this.user_name)
    localStorage.setItem('user_id', this.id)
  }

  removeUser() {
    localStorage.removeItem('token')
    localStorage.removeItem('user_id')
    localStorage.removeItem('user_name')
  }
}

export default class BaseService {
  static request(withAuth = false) {
    return new Http(this.entity, withAuth)
  }

  static assertArray(data, message) {
    if (!Array.isArray(data)) {
      throw new HttpAssertionError(message)
    }
  }

  static assertNumber(data, message) {
    if (typeof data !== 'number') {
      throw new HttpAssertionError(message)
    }
  }

  static throwError(error, message) {
    if (error instanceof HttpAssertionError) {
      throw error
    }
    throw new HttpError(error, message)
  }

  static loginUser(data) {
    return new User(data).setUser()
  }

  static logoutUser() {
    return new User().removeUser()
  }
}
