<template>
  <div class="page-details">
    <div class="age-controls">
      <UiButton
        v-for="btn in ageButtons"
        :key="btn.alias"
        :active="$store.state.pages.ageAlias === btn.alias"
        :title="btn.name"
        @click.native="setAgeAlias(btn.alias)"
      />
    </div>
    <div class="item" v-for="(item, index) in dataGroups" :key="item.id">
      <UiTitle
        :subTitle="item.sub_title"
        :indexStandart="index"
        :title="item.title"
      />

      <div
        class="itemText"
        v-show="item.annotation"
        v-html="item.annotation"
      ></div>

      <UiStandards
        :items="item.standards"
        :units="item.article_units"
        :defaultUnit="item.default_unit && item.default_unit.name"
      />
      <div class="itemText" v-show="item.text" v-html="item.text"></div>
      <UiReasons
        :decreaseHtml="item.reasons_decrease_html"
        :increaseHtml="item.reasons_increase_html"
      />
      <div
        class="itemText"
        v-show="item.conclusion"
        v-html="item.conclusion"
      ></div>
    </div>
  </div>
</template>

<script>
import UiReasons from '@/components/UiReasons.vue'
import UiTitle from '@/components/UiTitle.vue'
import UiStandards from '@/components/UiStandards.vue'
import UiButton from '@/components/UiButton'

export default {
  name: 'IndexPage',

  components: {
    UiReasons,
    UiTitle,
    UiStandards,
    UiButton
  },

  mounted() {
    this.loadDetails()
  },

  computed: {
    detailsPageName() {
      return this.$route.params?.details
    },
    dataGroups() {
      return this.$store.state.pages.byAlias.get(this.detailsPageName)
    }
  },

  methods: {
    loadDetails() {
      this.$store.dispatch('pages/getPage', this.detailsPageName)
    },
    setAgeAlias(alias) {
      this.$store.dispatch('pages/setAgeAlias', alias)
    }
  },

  data() {
    return {
      ageButtons: [
        { name: 'Все', alias: 'all' },
        { name: 'Взрослые', alias: 'adults' },
        { name: 'Дети', alias: 'kids' }
      ]
    }
  },

  watch: {
    detailsPageName: {
      handler: function() {
        this.loadDetails()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.page-details {
  width: 100%;
  padding: 42px 36px;

  .age-controls {
    margin-bottom: 42px;
  }

  .item {
    color: #383838;
    &Text {
      line-height: 20px;
      margin-bottom: 20px;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      margin: 20px 0;
    }
  }
}

@media only screen and (max-width: $mobile-max-width) {
  .page-details {
    padding: 0;
  }
}
</style>
