<template>
  <UiContainer>
    <UiSidebar
      :isLoaded="isLoaded"
      :items="menuItems"
      :basePath="sidebarBaseNavPath"
      :current="currentPage"
      :isBookmarks="isBookmarks"
    />
    <UiBody>
      <UiPageNavCards
        v-if="!detailsPageName && accessAllowed"
        :items="subPages"
        :title="pageTitle"
        :anotation="annotation"
        :basePath="`${sidebarBaseNavPath}/${pageName}`"
      />
      <PageDetails v-if="isLoaded && detailsPageName && accessAllowed" />
      <About v-if="isToken && !detailsPageName && !pageName" />
      <AccessDenied v-if="!accessAllowed" />
    </UiBody>
  </UiContainer>
</template>

<script>
import UiSidebar from '@/components/UiSidebar.vue'
import UiBody from '@/components/UiBody.vue'
import UiContainer from '@/components/UiContainer.vue'
import UiPageNavCards from '@/components/UiPageNavCards.vue'
import PageDetails from './PageDetails.vue'
import AccessDenied from './AccessDenied.vue'
import About from './About.vue'

export default {
  name: 'IndexPage',

  components: {
    UiSidebar,
    UiBody,
    UiContainer,
    UiPageNavCards,
    PageDetails,
    About,
    AccessDenied
  },

  mounted() {
    if (this.isToken) {
      this.$store.dispatch('categories/getCategories')
      this.$store.dispatch(
        'bookmarks/getBookmarks',
        this.$store.state.user.user_id
      )
    }
  },

  computed: {
    isLoaded() {
      return this.isBookmarks
        ? this.$store.state.bookmarks.loaded &&
            this.$store.state.categories.loaded
        : this.$store.state.categories.loaded
    },
    isToken() {
      return !!this.$store.state.user.token
    },
    pageName() {
      return this.$route.params?.page
    },
    detailsPageName() {
      return this.$route.params?.details
    },
    isBookmarks() {
      return this.$attrs?.isBookmarks
    },
    sidebarBaseNavPath() {
      return this.isBookmarks ? '/bookmarks' : '/guide'
    },
    currentPage() {
      return this.$store.state.categories.byAlias.get(this.pageName)
    },
    menuItems() {
      return this.isBookmarks
        ? this.$store.getters['categories/bookmarks']
        : this.$store.getters['categories/all']
    },
    subPages() {
      return this.detailsPageName ? [] : this.currentPage?.children || []
    },
    pageTitle() {
      return this.detailsPageName ? '' : this.currentPage?.title || ''
    },
    annotation() {
      return this.currentPage?.annotation || ''
    },
    dataGroups() {
      return this.$store.state.categories.childrenByAlias.get(
        this.detailsPageName
      )
    },
    accessAllowed() {
      return !this.currentPage || this.currentPage?.is_checked
    }
  },

  methods: {
    setDocumentTitle() {
      const defaultTitle = 'Академия Нутрициологии'
      let title = this.isBookmarks ? 'Закладки' : 'Все Анализы'

      if (this.pageName) {
        title = this.currentPage?.title
      }

      if (this.dataGroups) {
        title = this.dataGroups.sub_title || this.dataGroups.title
      }

      window.document.title = title || defaultTitle
    }
  },

  watch: {
    '$route.path': {
      handler: function() {
        this.setDocumentTitle()
      },
      deep: true,
      immediate: true
    },
    '$store.state.categories': {
      handler: function() {
        this.setDocumentTitle()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
