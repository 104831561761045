<template>
  <UiButton
    class="ui-header-nav-button component"
    :active="$route.path === path"
    :title="title"
    @click.native="onClick"
  />
</template>

<script>
import UiButton from './UiButton'

export default {
  name: 'UiHeaderNavButton',
  components: { UiButton },
  props: {
    path: {
      type: String,
      default: 'index'
    },
    title: {
      type: String,
      default: 'Button'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick: function() {
      if (!this.disabled) {
        this.$router.push(this.path)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.ui-header-nav-button {
  height: 50px;
  font-size: 16px;
  margin: 0 6px;
}

@media only screen and (max-width: $mobile-max-width) {
  .ui-header-nav-button {
    flex: 1;
    height: 34px;
    font-size: 13px;
    margin: 0 5px;
    line-height: 13px;
    padding: 6px 12px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
