<template>
  <div class="about-page">
    <h1>О руководстве</h1>
    <p>
      Данное электронное руководство – удобный справочный инструмент. В базе
      собраны медико-биологическое значение показателя, наиболее часто
      встречаемый референсный диапазон, некоторые известные превентивные нормы,
      причины и риски отсутствия нормальных показателей.
    </p>
    <p>
      Удобный интерфейс и функционал облегчают пользование электронным
      руководством, что позволяет эргономично распланировать медикам и
      нутрициологам план обследования, а подопечным понять, что именно означает
      врачебное заключение.
    </p>
    <p>
      Изучение руководства способствует выстраиванию понимания между медиками и
      пациентами, нутрициологами и подопечными и оптимизации диагностики за
      состоянием организма.
    </p>
    <p> <img src="@/assets/lock-red.svg" style="width: 13px;height: 13px;vertical-align: baseline;"> Означает, что у вас истек срок доступа</p>
    <p>
      Разделы, помеченные <img src="@/assets/lock.svg" style="width: 13px;height: 13px;vertical-align: baseline;"> находятся в разработке
    </p>
    <p>
      Разделы, помеченные <i class="el-icon-star-on" style="color: #4ebd7a;"></i> появятся в списке "закладок"
    </p>
    <p>
      Берегите здоровье!
    </p>
    <p v-if="isBlocked" style="font-size: 18px; font-weight: 600; color: #bd4e5a">Истек срок доступа</p>
    <!--    <p class="little">-->
    <!--      <i>*Некоторые разделы находятся в разработке!</i>-->
    <!--    </p>-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('categories', {
      isBlocked: state => state.isBlocked
    })
  }
}
</script>

<style scoped lang="scss">
.about-page {
  padding: 42px 36px;

  h1 {
    margin-bottom: 32px;
    font-weight: bold;
    font-size: 24px;
    line-height: 110%;
    color: #333c4d;
  }

  p {
    line-height: 24px;
    color: #383838;
  }

  p + p {
    margin-top: 24px;
  }

  .little {
    font-size: 12px;
  }

  @media only screen and (max-width: $mobile-max-width) {
    padding: 15px;
  }
}
</style>
