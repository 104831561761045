<template>
  <el-button
    class="back-button"
    icon="el-icon-arrow-left"
    circle
    @click.native="onClick"
  ></el-button>
</template>

<script>
export default {
  name: 'UiBackButton',

  methods: {
    onClick: function() {
      if (window.history.length > 2) {
        this.$router.go(-1)
      } else {
        const routeArr = this.$route.path.split('/')
        routeArr.pop()
        this.$router.replace(routeArr.join('/'))
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.back-button {
  border: none;
  font-size: 22px;
  padding: 7px;
  margin-top: 0;
  margin-left: -7px;
  background: none;
  color: #4ebd7a;
}

@media only screen and (max-width: $mobile-max-width) {
  .back-button {
    display: inline-block;
  }
}
</style>
