<template>
  <div class="error">
    <h1>404</h1>
    <p>Страница не найдена</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',

  mounted() {
    if (this.$store.state.user.token) {
      this.$store.dispatch('categories/getCategories')
      this.$store.dispatch(
        'bookmarks/getBookmarks',
        this.$store.state.user.user_id
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 92px);
  color: #c7d0dc;

  h1 {
    font-size: 46px;
    margin-bottom: 32px;
    font-weight: 600;
  }

  p {
    font-size: 24px;
  }
}

@media only screen and (max-width: $mobile-max-width) {
  .error {
    height: calc(100vh - 166px);

    h1 {
      font-size: 36px;
    }

    p {
      font-size: 18px;
    }
  }
}
</style>
